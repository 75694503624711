.product {
}

.products-section {
  background-color: rgba(66, 133, 244, 0.09);
}
.about-product {
    text-align: center;
    color: #2F3F56;
    margin: auto;
    padding: 150px 220px;
}

.about-product p span{
    font-size: 1.8rem;
}
.product-title {
  padding: 20px 0px;
  font-size: 3rem;
  font-weight: 400;
  color: #2f3f56;
  text-align: center;
}
.product-header {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.product-card {
  width: 330px;
  height: auto;
  background: rgba(225, 233, 235, 0.4);
  position: absolute;
  left: 220px;
  top: 70px;
  border-radius: 10px;
  padding: 20px 25px;
}

.product-card-tittle {
  font-size: 1.5rem;
  font-weight: 500;
  color: #2f3f56;
}

.product-card-content {
  font-size: 0.85rem;
  /* font-weight: 500; */
  color: #2bd1ba;
}

.product-banner {
  width: 60%;
  height: 70%;
  float: right;
  margin-top: 150px;
  margin-right: 170px;
}

.product-banner img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.products-wrapper {
  margin: 0 auto;
  margin-top: 40px;
  width: 90%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* align-items: center; */
}

.products-card {
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 450px;
  background-color: #ffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 20px 0px;
}

.products-card-image {
  width: 100%;
  height: 60%;
}

.products-card-image img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.products-card-title {
  width: 100%;
  padding: 0px 20px;
  padding-top: 20px;
  font-size: 1.5rem;
  color: #1f287b;
}

.products-card-content {
  font-size: 0.8rem;
  padding: 5px 20px;
}

.products-pansophic-continer {
  height: 400px;
  margin: 90px 0px;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
}

.products-pansophic-title {
  text-align: center;
  font-size: 3rem;
  color: #126569;
}

.products-pansophic-subtitle {
  font-size: 1rem;
  text-align: center;
  color: #1a264b;
}


@media screen and (max-width: 991px) {
  .product-card{
    width: 80%;
    left:unset;
   top: 90px;
  }
  .product-banner{
    width: 90%;
    height: 300px;
    margin-right: unset;
    margin-top: 315px;
  }
  .about-product {
    padding: 70px 20px;
  }
  .product-header {
    justify-content: center;
  }
}