nav{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    z-index: 100;
    /* box-shadow: 0 1px 2px 0 rgba(0,0,0,0.16); */
}

.logo {
    width: 70px;
    height: 60px;
}

.logo img{
    width: 100%;
    height: 100%;
}
.services_list{
    position: relative;
}
.dropdown{
    display: none;
    /* --- */
    visibility: hidden;
    opacity: 0;
    position: absolute;
padding-left: 0;
    left: 0;
    display: none;
    background: white;
}
.services_list:hover .dropdown{
display: block;
visibility: visible;
opacity: 1;
display: block;
min-width: 250px;
text-align: left;
padding-top: 20px;
box-shadow: 0px 3px 5px -1px #ccc;
}
.dropdown li{
    /* ul li ul li { */
        clear: both;
        width: 100%;
        text-align: left;
        margin-bottom: 20px;
        border-style: none;
        margin-left: 5px;

}
.dropdown li a:visited{
    padding-left: 10px;
    border-left: 2px solid #3ca0e7;
    transition: all 0.3s ease;
}
.kainskep_logo{
    height: 40px;
    margin-left: 2rem;
}
.navigation{
    margin-left: auto;
}
.list{
    display: flex;
    align-items: center;
    list-style: none;
    gap: 2rem;
    color: black;
    font-weight: 400;
    font-size: 1.1rem;
    margin-right: 2rem;
}
.list_items a{
    color: #4285F4;
}
.list_items a:active{
        border-bottom: 3px solid #0bb4e5 !important;     
}




.modal-header{
    width: 100%;
    display: flex;
}

.modal-image{
    width: auto;
}
.modal-image div{
    font-size: 2.5rem;
    color: #244188;
    margin-bottom:5px ;
}

.modal-image img{
     width: 400px;
     height: 350px;
     /* border-radius: 45px; */
}

.contact-content{
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: space-evenly;
}

.contact-tittle{
    padding: 0px 60px;
    font-size: 1.5rem;
    color: #3E9FD8;
}

.contact-tittle span{
    color: #244188;
}

.contactus-details{
    padding: 10px 60px;
}
.email-address{
    display: flex;
    align-items: center;
    color: #244188;
}
.mobile-number{
    display: flex;
    align-items: flex-start;
    color: #244188;
}
.address{
    display: flex;
    align-items: flex-start;
    color: #244188;
}


@media screen and (max-width: 991px) {
    .list{
        gap: 0.5rem;
    }
    .css-hyum1k-MuiToolbar-root{
        padding-right: unset !important;
    }
}