*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  font-size: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
@media (min-width: 768)
{
    body
    {
        font-size: 70%;
    }
}
@media (min-width: 578)
{
    body
    {
        font-size: 40%;
    }
}