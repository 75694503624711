 .Services{
    height: auto;
 }
.services-heading{
    margin-top: 100px;
    margin-left: 70px;
    font-size: 3rem;
    font-weight: 400;
    /* margin: 60px 0px; */
    color: #2BD1BA;
}
.services-wrapper{
    margin: 0 auto;
    margin-top: 40px;
    width: 70%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    /* border: 1px solid ; */
    justify-content: space-around;
    /* align-items: center; */
}

.service-card{
    width: 360px;
    height: 450px;
    background-color: #ffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin:20px 0px;
}

.service-card-image{
    width: 100%;
    height: 60%;
}

.service-card-image img{
   width: 100%;
   height: 100%;
   border-radius: 10px;
}

.service-card-title{
    padding:0px 20px;
    padding-top:20px ;
    font-size: 1.5rem;
    color: #1F287B;
}

.service-card-content{
    font-size: 0.9rem;
    padding:5px 20px;
}

.services-pansophic-continer{
    height: 400px;
    margin: 90px 0px;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
}

.services-pansophic-title{
    text-align: center;
    font-size: 3rem;
    color: #126569;
}

.services-pansophic-subtitle{
    font-size: 1rem;
    text-align: center;
    color: #1A264B;
}


@media screen and (max-width: 991px) {
    .services-wrapper{
        width: auto;
    }
    .services-heading{
        font-size: 2rem;
        text-align: center;
        margin-left: unset;
    }
}