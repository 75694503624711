*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  background-color:white;
  width: 6px;
}


/* Track */
/* ::-webkit-scrollbar-track {
  background-color: hsla(235, 21%, 11%, 0.322);
  box-shadow: 0 0 3px hsl(235, 21%, 11%);
  border-radius: 10px;
} */

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #5FC1C4;
  height: 320px;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4D6EF2;
}
.text-center{
  text-align: center;
}
li{
  list-style: none;
  cursor: pointer;
}
li a{
  text-decoration: none;
  color: black;
}

