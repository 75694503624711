.image_section{
    /* height: 100vh; */
    background-repeat: no-repeat;
    width:100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.tittle-box{
    width: 30vw;
    height: 30rem;
  margin-left:3rem ;
  margin-top: 10rem;
}
.tittle-box p{
    color: #1F287B;
}
.head-title{
    font-size: 3rem;
    color: #F2D12D;
}
.head-title span{
    font-size: 2rem;
         color: #4D6EF2;
}

.image-container{
  width: 1000px;
  height: 800px;
}

.image-container img{
    width: 100%;
    height: 100%;
}



.pansophic-continer{
    display: flex;
    flex-direction: column !important;
}
.pansophic-title{
    text-align: center;
    font-size: 3rem;
    color: #126569;
}

.pansophic-subtitle{
    font-size: 1rem;
    text-align: center;
    color: #1A264B;
}





.consulting_section{
    margin-top: 5rem;
}
.consulting_section h3{
    font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: 0.4rem;
}
.consulting_section p{
    font-size: 1.1rem;
    line-height: 22px;
    letter-spacing: 1px;
    margin-bottom: 0.4rem;
}
.consulting_section .button{
    margin-top: .4rem;
}

 .consult_image{
    height: 280px;
} 
/* -------------- */
.what_we_do_section{
margin-top: 5rem;
margin-bottom: 10rem;
}
.what_we_do_section>h3{
    margin-bottom: 10rem;
}
.card_heading{
  margin-top: 10px;
}
.card_para{
margin-top: 20px;
}



.what_we_do_section{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(43,209,186,0.1)
}
.services-tittle{
    font-size: 3rem;
    font-weight: 400;
    margin: 60px 0px;
    color: #2BD1BA;
}
.services-container{
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.card{
    width: 350px;
    height: 450px;
    background-color: #FFFFFF;
    border-radius: 10px;
    margin: 20px 0px;
}

.card-image{
    width: 100%;
    height: 300px;
}
.card-image img{
    width: 100%;
    height: 100%;
}
.card-title{
    padding:0px 20px;
    padding-top:20px ;
    font-size: 1.5rem;
    color: #1F287B;
}
.card-content{
    font-size: 0.9rem;
    padding:5px 20px;
}
.consulting-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 150px;
}

.consulting-image-container{
    width: 35%;
    /* height: 550px; */
     border-radius: 50%;
     font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.consulting-image-container img{
    width: 100%;
    height: 100%;
    
}


.consulting-title{
    font-size: 2.5rem;
    color: #1A264B;
}

.consulting-content{
    padding: 10px 0px;
    text-align: center;
    width: 35%;
    color: #126569;
}

.consulting-button{
    width: 300px;
    height: 40px;
}

.consulting-button Button{
    width: 100%;
    height: 100%;
    background-color: #5FC1C4;
    border-radius: unset;
    color: white;
}












button{
    color: black;
    padding: 7px 10px;
    /* color: white; */
    border-color: #22c8e5;
    /* border:transparent; */
    background-color: white;
    border-radius: 20px;
    width: 50%;
    cursor: pointer;
    outline: none;
}
button:hover{
    background-color:#22c8e5;
    /* background-color:transparent; */
    color: black;
}



@media screen and (max-width: 991px) {
    .tittle-box{
        width: 50vw;
        height: auto;
        margin-left: 2rem;
        margin-top: 4rem;
    }
    .image-container{
        width: auto;
        height: auto;
    }
    .services-container{
        width: 90%;
    }
    .consulting-title{
      font-size: 1.7rem;
    }
    .what_we_do_section{
        margin-bottom: 8rem;
    }
}