
.ft-logo{
    width: 180px;
    height: 160px;
}

.ft-logo img{
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 20%;
    border-top-right-radius: 20%;
    /* border-top-left-radius: 5%;
    border-bottom-right-radius: 5%; */

}

footer{
    display: flex;
    height: 360px;
    background-color: #5FC1C4;
    color:white;
    padding: 60px 40px;
}

footer li{
    list-style: none;
    /* line-height: 20px; */
}
 .footer_under{
    background-color: #5FC1C4;
    color: white;
}
@media screen and (max-width: 991px) {
    footer{
      justify-content: center;
    }
    footer div{
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        text-align: center;
    }
}